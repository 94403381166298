<template>
  <AppPage>
    <template v-slot:app-bar>
      <v-btn icon @click="$router.replace('/search-vet')">
        <v-icon color="black">mdi-close</v-icon>
      </v-btn>
      <v-spacer></v-spacer>
      <v-toolbar-title>{{
        $vuetify.lang.t("$vuetify.vets_located_near_you")
      }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon @click="openFilter">
        <v-img
          src="@/components/svg/icon/filter.svg"
          height="24"
          contain
        ></v-img>
      </v-btn>
    </template>

    <template v-if="loading">
      <v-skeleton-loader
        v-for="n in 5"
        :key="n"
        type="list-item-avatar-three-line,divider"
      ></v-skeleton-loader>
    </template>

    <!--  -->
    <div class="vet-list" v-else>
      <template v-for="vet in vetList">
        <VetCard :key="vet.id" :vet="vet" show-direction />
        <v-divider :key="vet.id + 'index'"></v-divider>
      </template>
    </div>
  </AppPage>
</template>

<script>
import VetCard from "@/components/cards/vet-card";

import { createNamespacedHelpers } from "vuex";
const vetModule = createNamespacedHelpers("vet");
const popupModule = createNamespacedHelpers("popup");
export default {
  name: "vet-list",
  components: {
    VetCard,
  },
  computed: {
    ...vetModule.mapState(["loading", "vetList"]),
  },
  methods: {
    // ...vetModule.mapActions(["FETCH_VET_LIST"]),
    ...popupModule.mapMutations(["SET_SHEET"]),
    openFilter() {
      this.SET_SHEET({
        showSheet: true,
        sheetComponent: "vet-filter",
      });
    },
  },
  created() {
    // this.FETCH_VET_LIST();
  },
};
</script>

<style lang="scss" scoped></style>
